import React, { createContext, useState, useMemo, useEffect } from "react"
import { LoadingBackdrop } from "../Reusable";
// prettier-ignore
function isCurrentlySSR(){
    return typeof window === 'undefined';
  }

type LoadingBackdropData = {
  startLoading: (info?: string) => void
  stopLoading: () => void
}

export const LoadingBackdropContext = createContext<LoadingBackdropData>(
  null as any
)

export const LoadingBackdropProvider = ({ children }: { children: any }) => {
  const [info, setInfo] = useState("")
  const [open, setOpen] = useState(false)

  return (
    <LoadingBackdropContext.Provider
      value={{
        startLoading: (info?: string) => {
          setInfo(info)
          setOpen(true)
        },
        stopLoading: async () => {
          setOpen(false)
        },
      }}
    >
      {children}
      {!isCurrentlySSR() && <LoadingBackdrop open={open} info={info}/>}
    </LoadingBackdropContext.Provider>
  )
}
export function useLoadingBackdropContext(){ return React.useContext(LoadingBackdropContext) }
export default LoadingBackdropProvider
