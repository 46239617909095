import React, { createContext, useState, useMemo, useEffect } from "react"
import { print, genId, slugify } from "./utils"
import JSZip from "jszip"
import LoadingBackdropProvider, {
  LoadingBackdropContext,
  useLoadingBackdropContext,
} from "./providers/LoadingBackdrop"
const xmlParser = require("fast-xml-parser")

const XML = {
  parse: xmlParser.parse,
  stringify: (data) => new xmlParser.j2xParser({}).parse(data),
}

const zip = new JSZip()

async function getTableOfContent(zipFile: JSZip): Promise<string[]> {
  const tocFilename = Object.keys(zipFile.files).find((filename) =>
    filename.endsWith("toc.ncx")
  )
  if (!tocFilename) return []
  const x = await zip.file(tocFilename).async("string")
  const d = XML.parse(x)
  let chaps = d?.ncx?.navMap?.navPoint?.map((e) => {
    //print(e.navLabel.text);
    return e.navLabel.text
  })
  return chaps
}


export type chapNotes = {
  name:string
  num?:string;
  notes:{content:string; page:string;}[]
}[]

async function getNotes(zipFile: JSZip, name:string, bookNotes:BookNote[]):Promise<BookNote>{
  /*
  const htmlFiles = Object.keys(zipFile.files).filter((filename) =>
    filename.endsWith("html")
  )
  let chaps = [];
  htmlFiles.slice(0,20).forEach(async filename => {
    const x = await zip.file(filename).async("string")
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(x,"text/xml");
    const resp = xmlDoc.querySelectorAll('h1,h2')
    print("got",filename)
    print(resp.length, resp[0],resp[1])
  })*/
  // 1: find the name of the notes
  let m = {}
  name.split(/[,\s]/g).forEach(e => {
    m[e]=true
  })
  const b = bookNotes.find(e => {
    const r = e.name.split(/[,\s]/g)
    return r.every(e => m[e] != null)
  })
  return b;
}

function parseKoreaderClipboardFile(koreaderClipboard: string): BookNote[] {
  let s = koreaderClipboard
  const blocks = s.split("-=-=-=-=-=-\n\n")
  const books: BookNote[] = blocks.map((e) => {
    let d: BookNote = { name: "", notes: [] }
    d.name = e.split("-- Page", 2)[0].trim()
    d.notes = e
      .split("-- Page:")
      .slice(1)
      .map((s) => {
        let p = {
          content: s.substr(s.indexOf("\n")).split("-=-=-=-=-=-")[0].trim(),
          page: s.split(",", 2)[0].trim(),
        }
        return p
      })
    return d
  })
  return books
}

async function getImage(zipFile: JSZip): Promise<string | null> {
  const filename = Object.keys(zipFile.files).find(
    (filename) => filename.endsWith(".jpg") || filename.endsWith(".png")
  )
  print("image", filename)
  if (!filename) return null
  const x = await zip.file(filename).async("string")
  return "data:image/jpeg;base64," + btoa(x)
}
export type FileEpub = {
  id: string
  name: string
  slug: string
  status: "loading" | "ok" | "error"
  createdAt: number
  imageB64?: string
  tableOfContent: string[]
  bookNote?:BookNote
}

export type BookNote = {
  name: string
  notes: { content: string; page: number | string }[]
}

type LocalAppData = {
  addFile: (payload: Promise<any>, name: string) => void
  addClipboard: (payload: Promise<any>, name: string) => void
  files: FileEpub[]
  bookNotes: BookNote[]
  removeFile: (file: FileEpub) => void
  getFileDataFromSlug: (slug: string) => FileEpub | null
  goHome: () => void
}

const localStorage =
  typeof window == `undefined`
    ? {
        getItem(str) {
          return null
        },
        setItem(str, v) {
          return
        },
      }
    : window.localStorage

export const AppDataContext = createContext<LocalAppData>(null as any)

function useStateCache<Val = any>(
  key: string,
  defaultValue: Val
): [Val, React.Dispatch<React.SetStateAction<Val>>] {
  const x = useState<Val>(
    (JSON.parse(localStorage.getItem(key)) as Val) || defaultValue
  )
  const timeout = React.useRef(null)
  const val = React.useRef(null)
  val.current = x[0]

  useEffect(() => {
    //save in cache
    if (timeout.current) return
    timeout.current = setTimeout(() => {
      localStorage.setItem(key, JSON.stringify(val.current))
      timeout.current = null
    }, 100)
  }, [x[0]])

  return x
}
export const AppDataStore = ({ children }: { children: any }) => {
  const [files, setFiles] = useStateCache<FileEpub[]>("files", [])
  const [bookNotes, setBookNotes] = useStateCache<BookNote[]>("bookNotes", [])
  const [page, setpage] = useState(false)

  return (
    <LoadingBackdropProvider>
    <LoadingBackdropContext.Consumer>
      {({stopLoading,startLoading})=>(
            <AppDataContext.Provider
              value={{
                files,
                bookNotes,
                goHome: () => {},
                addClipboard: async (payload, name) => {
                  startLoading("getting file")
                  const blob = await payload
                  const s:string = await new Promise((ok)=>{
                    let reader = new FileReader()
                    reader.readAsText(blob)
                    reader.onload = function () {
                      //console.log(reader.result)
                      ok(reader.result.toString());
                    }
                  })
                  const bookNotes = parseKoreaderClipboardFile(s)
                  setBookNotes(bookNotes)
                  stopLoading()
                },
                addFile: async (payload, name) => {
                  const id = genId(4)
                  setFiles((files) => [
                    {
                      id,
                      name,
                      status: "loading",
                      slug: slugify(name.split(".").slice(0, -1).join(".")),
                      createdAt: Date.now(),
                      tableOfContent: [],
                    },
                    ...files,
                  ])
                  const blob = await payload
                  const x = await zip.loadAsync(blob)
                  const bookNote = await getNotes(x,name,bookNotes);
                  print("bookNote",bookNote)
                  const resp: any = {
                    tableOfContent:await getTableOfContent(x),
                    imageB64: null, //await getImage(x),
                    bookNote,
                  }
                  setFiles((files) =>
                    files.map((e) =>
                      e.id == id ? { ...e, status: "ok", ...resp } : e
                    )
                  )
                },
                removeFile: (file) => {
                  setFiles((files) => files.filter((e) => e.id != file.id))
                },
                getFileDataFromSlug: (slug) => {
                  const file = files.find((e) => e.slug == slug)
                  if (!file) return null
                  return file
                },
              }}
            >
              {children}
            </AppDataContext.Provider>
      )}
    </LoadingBackdropContext.Consumer>

    </LoadingBackdropProvider>
  )
}
